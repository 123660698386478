import React from 'react';
import Header from '../components/header';
import '../styles/index.scss';

const Template = (props) => (
  <div className="container">
    <Header {...props} />
    {props.children}
  </div>
);

export default Template;
