import React from 'react';
import Link from 'gatsby-link';

import '../styles/header.scss';
import GithubLogo from '../components/icons/github';
import GitlabLogo from '../components/icons/gitlab';
import IconTwoSides from '../components/icon-two-sides';
import LinkedIn from '../components/icons/linkedin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Header = () => (
  <nav className="navbar">
    <div className="navbar-container">
      <Link to={'/'}>
        <FontAwesomeIcon icon="angle-left" />
      </Link>
      <IconTwoSides
        front={<FontAwesomeIcon icon="heartbeat" />}
        back={<LinkedIn />}
      />
      <IconTwoSides
        front={<FontAwesomeIcon icon="grin-squint-tears" />}
        back={<GitlabLogo />}
      />
      <IconTwoSides
        front={<FontAwesomeIcon icon="globe-africa" pulse />}
        back={<GithubLogo />}
      />
      <div className="code-close">
        <FontAwesomeIcon icon="slash" rotation={90} size="sm" />
        <FontAwesomeIcon icon="angle-right" />
      </div>
    </div>
  </nav>
);

export default Header;
