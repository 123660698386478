import React, { useState } from 'react';
import PropTypes from 'prop-types';

const IconTwoSides = ({ front, back }) => {
  const [show, setShow] = useState(false);
  return (
    <div onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
      {show ? back : front}
    </div>
  );
};

IconTwoSides.defaultProps = {};

IconTwoSides.propTypes = {};

export default IconTwoSides;
