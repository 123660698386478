import React from 'react'; // eslint-disable-line

const LinkedIn = (props) => (
  <div>
    <a href="https://www.linkedin.com/in/ldmcdaniel/" target="_blank">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="512"
        height="512"
        viewBox="0 0 430.117 430.118"
      >
        <title>LinkedIn</title>
        <path
          d="M398.355 0H31.782C14.229 0 .002 13.793.002 30.817v368.471c0 17.025 14.232 30.83 31.78 30.83h366.573c17.549 0 31.76-13.814 31.76-30.83V30.817C430.115 13.798 415.904 0 398.355 0zM130.4 360.038H65.413V165.845H130.4v194.193zM97.913 139.315h-.437c-21.793 0-35.92-14.904-35.92-33.563 0-19.035 14.542-33.535 36.767-33.535 22.227 0 35.899 14.496 36.331 33.535 0 18.663-14.099 33.563-36.741 33.563zm266.746 220.723h-64.966v-103.9c0-26.107-9.413-43.921-32.907-43.921-17.973 0-28.642 12.018-33.327 23.621-1.736 4.144-2.166 9.94-2.166 15.728v108.468h-64.954s.85-175.979 0-194.192h64.964v27.531c8.624-13.229 24.035-32.1 58.534-32.1 42.76 0 74.822 27.739 74.822 87.414v111.351zM230.883 193.99c.111-.182.266-.401.42-.614v.614h-.42z"
          fill="#0077b5"
        />
      </svg>
    </a>
  </div>
);

export default LinkedIn;
